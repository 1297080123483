import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import { Container, Grid } from "@mui/material"
import CassandraWikiCard from "../components/CassandraWikiCard/CassandraWikiCard"
import PageTitle from "../components/PageTitle/PageTitle"
import "./style/cassandra-wiki.scss"
import { Helmet } from "react-helmet"
const cassandraWiki = () => {
  const [filter, setFilter] = useState("Glossary")
  // const data = useStaticQuery(graphql`
  //   query MyQuery {
  //     wiki: allAirtable(filter: { table: { eq: "Glossary Main Page" } }) {
  //       nodes {
  //         data {
  //           Basic_Definition
  //           Glossary_Term
  //           Supporting_Links
  //           Examples
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Layout>
      <Helmet>
        <title> Cassandra Wiki - Cassandra Link</title>
        <meta
          name="description"
          content="Explore the Cassandra Wiki on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:title" content="Cassandra Link - Cassandra Wiki" />
        <meta
          property="og:description"
          content="Explore the Cassandra Wiki on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://cassandra.link/cassandra-wiki"
        />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cassandra Link - Cassandra Wiki" />
        <meta
          name="twitter:description"
          content="Explore the Cassandra Wiki on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta name="twitter:image" content="../../images/Logo.svg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Cassandra-Wiki - Cassandra Link",
            keywords: "Cassandra Link, Cassandra-Wiki, Wiki, Cassandra",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>
        {/* Open Graph */}
        <meta property="og:title" content={"Cassandra-Wiki - Cassandra Link"} />

        {/* Other meta tags you may consider adding */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>
      <Container maxWidth="lg">
        <Grid>
          <PageTitle title="Cassandra.Wiki" />
          {/* <CassandraWikiCard data={data} /> */}
        </Grid>
      </Container>
    </Layout>
  )
}

export default cassandraWiki
